<template>
  <div class="col-4 offset-4">
    <v-simple-table dark dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left text--black">
            Atributo
          </th>
          <th class="text-left text--black">
            Valor
          </th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              isLoggedIn
            </td>
            <td>
              {{ $store.state.loggedIn }}
            </td>
          </tr>
          <tr>
            <td>
              token
            </td>
            <td>
              {{ $store.state.token }}
            </td>
          </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="2">
            <v-btn color="primary" block @click="resetData">Reset</v-btn>
          </td>
        </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

export default {
  name: "Test",
  computed: {
    ...mapState([
      'user',
      'isLoggedIn'
    ]),
  },
  methods: {
    ...mapMutations({
      setLoggedIn: 'SET_LOGGED_IN',
      setToken: 'SET_TOKEN'
    }),
    resetData () {
      this.setLoggedIn(false)
      this.setToken(false)
    }
  }
}
</script>

<style scoped>

</style>
